import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const reviewsCaro = (function(){
	let my = {};

	my.init = function(){
		window.addEventListener('load', () => {
			if(document.querySelector('#reviews_caro')) {	
				AutomatitCarousel({
					element: document.getElementById('reviews_caro'),
					speed: 1420,
					intervalTiming: 0,
					beforeSlide: fixBoxHeight,
					autoVerticalCentering: false
				});
			}

			if(document.querySelector('#reviews_caro_mobile')) {
				AutomatitCarousel({
					element: document.getElementById('reviews_caro_mobile'),
					speed: 1420,
					intervalTiming: 0,
					beforeSlide: fixBoxHeight,
					autoVerticalCentering: false
				});
			}
		});

		

		let caroHeight = 0;
		function fixBoxHeight(){
			[].forEach.call(document.querySelectorAll('.review .read_more'), function(el){
				if(caroHeight === 0){
					let inner = el;
					while(!inner.classList.contains('caro_inner')){
						inner = inner.parentElement;
					}
					caroHeight = inner.offsetHeight;
				}
				el.style.display = "block";
				let parent = el.parentElement;

				let review = el.closest('.review');
				review.style.height = null;

				parent.querySelector('.short').style.display = 'block';
				parent.querySelector('.long').style.display = 'none';
				while(!parent.classList.contains('caro_inner')){
					parent = parent.parentElement;
				}
				parent.style.height = caroHeight + 'px';
			});
		}

		[].forEach.call(document.querySelectorAll('.review .read_more'), function(btn){
			btn.addEventListener('click', function(e){
				if(caroHeight === 0){
					let el = btn;
					while(!el.classList.contains('caro_inner')){
						el = el.parentElement;
					}
					caroHeight = el.offsetHeight;
				}
				e.currentTarget.style.display = 'none';
				let parent = e.currentTarget.parentElement;
				parent.querySelector('.short').style.display = 'none';
				parent.querySelector('.long').style.display = 'block';

				let review = parent.closest('.review');
				review.style.height = 'auto';

				while(!parent.classList.contains('caro_slide')){
					parent = parent.parentElement;
				}
				let height = parent.offsetHeight;
				
				while(!parent.classList.contains('caro_inner')){
					parent = parent.parentElement;
				}
				parent.style.height = height + 'px';
			});
		});
	};

	return my;
})();