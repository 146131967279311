import { navToggle } from './header.js';
import { helpToggle } from './help.js';
import { contactSubmit } from './contact.js';
import { maps } from './map.js';
import { homeSearch } from './home.js';
import { reviewsCaro } from './reviews.js';
import { facilityCaro, facilityMap, unitFilters } from './facility.js';

navToggle.init();

homeSearch.init();

helpToggle.init();

maps.init();

reviewsCaro.init();

unitFilters.init();
facilityCaro.init();
facilityMap.init();

if (document.getElementById('contact_form')) {
	contactSubmit();
}
