export const helpToggle = (function(){
	let my = {};

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('.help_content'), (title) => {
			title.addEventListener('click', _toggleItem);
		});
	}

	function _toggleItem(e){
		const item = e.currentTarget;
		const container = item.querySelector('.help_item_content');
		if(item.classList.contains('open')){
			item.classList.remove('open');
			container.style.height = 0;
		}else{
			item.classList.add('open');
			let height = item.querySelector('.help_item_content_wrapper').offsetHeight;
			container.style.height = height + "px";
		}
	}

	my.init = function(){
		if(document.getElementById('help_wrapper')) {
			_assignListeners();
			setTimeout(function(){
				document.querySelector('.help_content').click();
			}, 500);
		}
	};

	return my;
})();